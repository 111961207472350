<template>
    <div class="app-body">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-700">玖电钱包对账</span>
            <div class="a-flex-rcc">
                <el-button icon="el-icon-upload2" class="s-btn-exp" @click="exportfile">导出对账列表</el-button>
                <el-button icon="el-icon-upload2" class="s-btn-exp" @click="exportfileDetails">导出对账明细</el-button>
            </div>
        </div>
        <el-card class="el-main">
            <le-search-form @reset="handlerRest" @search="handlerSearch" :canPackUp="true" id="leSearch">
                <le-date-range 
                    label="日期" 
                    ref="dateRange" 
                    :minDate.sync="pageParam.params.startDay" 
                    :maxDate.sync="pageParam.params.endDay" 
                    valueFormat="yyyy-MM-dd HH:mm:ss" 
                    :defaultTime="['00:00:00','23:59:59']"
                    :clearable='false' />
            </le-search-form>
            <le-pagview ref="financeJDDZ" @setData="setTableData" :pageParam="pageParam" :pagerCount="5" :isFixed="true" >
                <el-table ref="financeJDDZList" :data="tableData" :highlight-current-row="true" v-sticky="{ top: 0, parent:'.el-card__body' }" style="width: 100%">
                    <el-table-column prop="countDate" label="日期" >
                        <template slot-scope="{ row }">
                            <span>{{ row.countDate?row.countDate:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="paymentName" label="订单分账金额（元）" >
                        <template slot-scope="{ row }">
                            <span>{{ util.numFormat(row.orderIncome || 0) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="incomePrice" label="商户钱包实际入账（元）" >
                        <template slot-scope="{ row }">
                            <span>{{ util.numFormat(row.walletIn || 0) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="startDate" label="提现成功金额（元）" >
                        <template slot-scope="{ row }">
                            <span>{{ util.numFormat(row.withdrawAmount || 0) }}</span>
                        </template>
                    </el-table-column>
                </el-table>
                <template slot="footerContent" slot-scope="scope" >
                    <div class="a-flex-rfsfs a-flex-wrap">
                        <div class="a-flex-cfsfs">
                            <span class="a-fs-16 a-c-master a-fw-b">￥{{util.numFormat(scope.val.orderIncome || 0)}}</span>
                            <span class="a-fs-12 a-c-normal">订单分账总额</span>
                        </div>
                        <div class="a-flex-cfsfs a-ml-20">
                            <span class="a-fs-16 a-c-master a-fw-b">￥{{util.numFormat(scope.val.walletIn || 0)}}</span>
                            <span class="a-fs-12 a-c-normal">商户钱包实际入账总额</span>
                        </div>
                        <div class="a-flex-cfsfs a-ml-20">
                            <span class="a-fs-16 a-c-master a-fw-b">￥{{util.numFormat(scope.val.withdrawAmount || 0)}}</span>
                            <span class="a-fs-12 a-c-normal">提现成功总额</span>
                        </div>
                        <div class="a-flex-cfsfs a-ml-20">
                            <span class="a-fs-16 a-c-master a-fw-b">￥{{util.numFormat(companyWithdrawIng || 0)}}</span>
                            <span class="a-fs-12 a-c-normal">提现中金额</span>
                        </div>
                    </div>
                </template>
            </le-pagview>
        </el-card>
    </div>
</template>

<script>
    import util from '../../../src/utils/util'
    export default {
        data () {
            return {
                tableData: [],
                util: util,
                pageParam: {
                    url: this.$Config.apiUrl.getCompanyBillDay,
                    method: "post",
                    params: {
                        "endDay": this.$getDay.getTodayBeforeDays(1) + ' 23:59:59',
                        "id": this.$route.query.companyId,
                        "payPlatform": this.$route.query.type,
                        "startDay": this.$getDay.getTodayBeforeDays(31) + ' 00:00:00'
                    },
                    freshCtrl: 1,
                },
                choiceDate: '',
                pickerOptions: {
                    onPick: ({ maxDate, minDate }) => {
                        this.choiceDate = minDate.getTime()
                        if (maxDate) {
                            this.choiceDate = ''
                        }
                    },
                    disabledDate: (time) => {
                        if (this.choiceDate) {
                            let one = 92 * 24 * 3600 * 1000
                            const minTime = this.choiceDate - one
                            const maxTime = this.choiceDate + one
                            return time.getTime() < minTime || time.getTime() > maxTime
                        }else{
                            return ''
                        }
                    },
                },
                companyWithdrawIng: '',
                payPlatformDic: []
            }
        },
        created () {
            this.getCompanyWithdrawIng()
            this.$getDic('payPlatform','select').then(res=>{ this.payPlatformDic = res; })
        },
        activated () {
            this.pageParam.freshCtrl++;
        },
        methods: {
            //获取设备列表
            setTableData(data) {
                this.tableData = data;
            },
            handlerRest() {
                this.pageParam.params = {
                    "endDay": this.$getDay.getTodayBeforeDays(1) + ' 23:59:59',
                    "id": this.$route.query.companyId,
                    "payPlatform": this.$route.query.type,
                    "startDay": this.$getDay.getTodayBeforeDays(31) + ' 00:00:00'
                };
                this.$refs['dateRange'].value1 = [this.$getDay.getTodayBeforeDays(31) + ' 00:00:00', this.$getDay.getTodayBeforeDays(1) + ' 23:59:59']
                this.handlerSearch()
            },
            handlerSearch() {
                if(this.$getDay.enumerateDaysBetweenDates(this.pageParam.params.startDay,this.pageParam.params.endDay).length > 92){
                    this.$message.error('最大查询范围92天')
                    return
                }
                this.$refs['financeJDDZ'].pageNum = 1
                this.pageParam.freshCtrl++;
            },
            getCompanyWithdrawIng () {
                this.$Axios._post({
                    url: this.$Config.apiUrl.getCompanyWithdrawIng,
                    params: {
                        companyId: this.$route.query.companyId,
                        payPlatForm: this.$route.query.type
                    },
                })
                .then(({ data }) => {
                    this.companyWithdrawIng = data.withdrawAmount
                })
            },
            exportfile () {
                if(this.$getDay.enumerateDaysBetweenDates(this.pageParam.params.startDay,this.pageParam.params.endDay).length > 365){
                    this.$message.error('最大导出范围365天')
                    return
                }
                this.$exfile({
                    code: 28,
                    fileName: this.pageParam.params.startDay + ' 至 ' + this.pageParam.params.endDay + ' 玖电钱包对账列表',
                    startTime: this.pageParam.params.startDay,
                    endTime: this.pageParam.params.endDay,
                    params: this.pageParam.params
                })
            },
            exportfileDetails () {
                if(this.$getDay.enumerateDaysBetweenDates(this.pageParam.params.startDay,this.pageParam.params.endDay).length > 31){
                    this.$message.error('最大导出范围31天')
                    return
                }
                this.$exfile({
                    code: 29,
                    fileName: this.pageParam.params.startDay + ' 至 ' + this.pageParam.params.endDay + ' 玖电钱包对账明细',
                    startTime: this.pageParam.params.startDay,
                    endTime: this.pageParam.params.endDay,
                    params: this.pageParam.params
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
		/*width: 0;宽度为0隐藏*/
		width: 0 !important;
		height: 0 !important;
	}
	/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
		height: 100% !important;
	}
    /deep/ .companySelect{
        .a-ml-20{
            margin-left: 10px !important;
        }
    }
</style>